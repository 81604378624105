* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

p {
  padding: 0.5rem 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffffe6;
  width: 100%;
  padding: 0 5%;
  height: 6rem;
}

.navLogo {
  height: 3rem;
}

.navbarBurger a {
  width: 100%;
}

.navbarLinks {
  display: none;
}

.sectionColorContainer {
  background-color: #f7e05f;
  width: 100%;
  padding: 15% 5% 10%;
}

.sectionContainer {
  background-color: #ffffffe6;
  width: 100%;
  padding: 15% 5% 10%;
}

.landingTitle {
  font-family: cartoon;
  font-size: 4rem;
  color: black;
  line-height: 1;
  margin: 1rem 0 0;
}

.landingPara {
  margin: 2rem 0 0;
}

.landingPara p {
  margin-bottom: 2rem;
}

.sectionTitle {
  font-family: cartoon;
  font-size: 4rem;
  color: black;
  line-height: 1;
  margin: 1rem 0 3rem;
}

.sectionContainer a:hover {
  color: #1a27c9;
  text-decoration: underline;
  text-decoration-thickness: 0.15rem;
}

.sectionTitleProject {
  font-family: cartoon;
  font-size: 4rem;
  color: black;
  line-height: 1;
  margin: 1rem 0 0;
}

.sectionPara {
  margin: 1rem 0 2rem;
}

.sectionPara a {
  color: #1a27c9;
}

.sectionPara a:hover {
  text-shadow: 0 0 0.5px #1a27c9, 0.5px 0 0 #1a27c9;
  text-decoration: underline;
}

.sectionPara h2:first-child {
  font-weight: bold;
  font-size: 1.2rem;
}

.sectionPara h2 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.projLinks {
  display: flex;
  align-items: center;
}

.contactFormContainer {
  border: 1px solid #1a27c9;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #f7e05f;
}

.contactForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
}

.contactForm input {
  background-color: #ffffffe6;
  border-radius: 0.25rem;
  padding-left: 0.5rem;
}

.flexContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 3rem 0 2rem;
}

.skillLogo {
  height: 5rem;
  width: 5rem;
}

.skillLogo:firs-child {
  margin-top: 2rem;
}

.photo {
  width: 25rem;
  border: 0.1rem solid #f7e05f;
  border-radius: 0.25rem;
}

.photo:first-child {
  margin-top: 1rem;
}

.photoYellowBg {
  width: 25rem;
  border: 1px solid #1a27c9;
  border-radius: 0.25rem;
}

.photoHeroSmall {
  width: 15rem;
  border: 1px solid #1a27c9;
  border-radius: 0.25rem;
}

.photoHeroBig {
  display: none;
}

.photoLand {
  width: 50rem;
  border: 0.1rem solid #1a27c9;
  border-radius: 0.25rem;
  margin-top: 2rem;
}

.photoProj {
  width: 40rem;
  border: 0.1rem solid #f7e05f;
  border-radius: 0.25rem;
}

.photoProjMore {
  display: none;
}

.photoProjYellowBg {
  width: 40rem;
  border: 0.1rem solid #1a27c9;
  border-radius: 0.25rem;
}

.modalPhoto {
  max-width: 25rem;
  max-height: 55rem;
}

.column {
  width: 100%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.pDesk {
  display: none;
  padding: 0;
}

.pMobile {
  display: inline-block;
  padding: 0;
}

.bullet {
  list-style-type: square;
  list-style-position: inside;
  padding: 0.5rem 0;
}

@media only screen and (min-width: 768px) {
  .navbarBurger {
    display: none;
  }

  .navbarLinks {
    display: flex;
    font-size: 1rem;
  }

  .navbarLinks li {
    list-style: none;
  }

  .navbarLinks li a {
    padding: 0.75rem;
    letter-spacing: 0.1rem;
  }

  .navbarLinks li a:hover,
  a:active {
    color: #1a27c9;
    text-decoration: underline;
    text-shadow: 0 0 0.5px #1a27c9, 0.5px 0 0 #1a27c9;
  }

  .landingTitle {
    font-size: 5rem;
    line-height: 1.5;
    margin: 1rem 0 0;
  }

  .landingPara {
    margin: 5rem 0 0;
  }

  .landingPara p {
    margin-bottom: 5rem;
  }

  .sectionColorContainer {
    background-color: #f7e05f;
    width: 100%;
    padding: 5%;
  }

  .sectionContainer {
    background-color: #ffffffe6;
    width: 100%;
    padding: 5%;
  }

  .sectionTitle {
    line-height: 1.5;
  }
  .sectionTitleProject {
    line-height: 1.5;
  }

  .contactFormContainer {
    width: 50%;
  }

  .photoHeroSmall {
    display: none;
  }

  .photoHeroBig {
    width: 25rem;
    border: 1px solid #1a27c9;
    border-radius: 0.25rem;
    display: block;
  }

  .column {
    float: left;
    width: 50%;
    padding: 1rem 2rem 0 0;
    vertical-align: middle;
  }

  .photo {
    width: 25rem;
    border: 2px solid #f7e05f;
    border-radius: 5px;
  }

  .photoYellowBg {
    width: 25rem;
    border: 1px solid #1a27c9;
    border-radius: 5px;
  }

  .photoLand {
    width: 50rem;
    border: 1px solid #1a27c9;
    border-radius: 5px;
    margin-bottom: 0;
  }

  .photoBox {
    position: relative;
    float: left;
  }

  .photoBox .photoProjMore {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }

  .photoBox:hover .photoProjMore {
    display: block;
    border: 0.1rem solid #1a27c9;
    border-radius: 0.25rem;
  }

  .modalPhoto {
    max-width: 50rem;
    max-height: 55rem;
  }

  .pDesk {
    display: inline-block;
  }

  .pMobile {
    display: none;
  }

  .buttonSpacing {
    margin-top: 1rem;
  }
}

@font-face {
  font-family: "cartoon";
  src: url("./assets/fonts/CartoonBlocks.ttf") format("truetype");
}
